@import 'mixins';

.View-Form-Action {
  button {
    @include xs {
      width: 100%;
    }
  }
}

.MuiDialog-root {
  z-index: 1000 !important;
}