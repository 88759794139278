.animate {
    border: none !important;
    -webkit-animation: pulsing 1.25s infinite !important;
    -moz-animation: pulsing 1.25s infinite !important;
    -ms-animation: pulsing 1.25s infinite !important;
    animation: pulsing 1.25s infinite !important;
    transition: all 300ms ease-in-out !important;

    &:hover {
        -webkit-animation: none !important;
    }

    @-webkit-keyframes pulsing {
        to {
            box-shadow: 0 0 0 8px rgba(255, 255, 0, 1);
        }
    }

    @-moz-keyframes pulsing {
        to {
            box-shadow: 0 0 0 8px rgba(255, 255, 0, 1);
        }
    }

    @-ms-keyframes pulsing {
        to {
            box-shadow: 0 0 0 8px rgba(255, 255, 0, 1);
        }
    }

    @keyframes pulsing {
        to {
            box-shadow: 0 0 0 8px rgba(255, 255, 0, 1);
        }
    }
}