.navbar-brand {
    color: #0094b5;
    float: left;
    height: 50px;
    padding: 10px 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
a {
    color: #337ab7;
    text-decoration: none;
}
a {
    background-color: transparent;
}


.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    border: 1px solid transparent;
    align-items: normal;
    padding: 0;
}


@media (min-width: 768px) {
    .navbar-header {
        float: left;
    }
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: block;
}

@media (min-width: 768px) {
    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }
}

.navbar-top-links {
    margin-right: 0;
}

.navbar-top-links li a {
    padding: 15px;
    min-height: 50px;
}


@media (min-width: 768px) {
    .navbar {
        border-radius: 4px;
    }
}

.navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px;
}

@media (min-width: 768px) {
    .navbar-static-top {
        border-radius: 0;
    }
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.green-icon {
    color: #35ac19;
}





.pull-right {
    float: right!important;
}

.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    box-sizing: border-box;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}


.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.h3, h3 {
    font-size: 24px;
}


.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
}

.btn {
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
}

.panel-default {
    border-color: #ddd;
}

.error-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: red;
    font-size: 28px;
    text-align: center;
    flex-direction: column;
}